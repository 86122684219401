import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { ThreatmetrixService } from '@freelancer/threatmetrix';

@Component({
  selector: 'webapp-compat-threatmetrix',
  template: ``,
})
export class CompatThreatmetrixComponent implements OnInit {
  constructor(private threatMetrixService: ThreatmetrixService) {}

  ngOnInit(): void {
    // Load the external script on page load
    this.threatMetrixService.getSession();
    window.webappCompatThreatmetrix = {
      getSession: this.threatMetrixService.getSession,
    };
  }
}
